import "./companyfinancials.css";

const CompanyFinancials = () => {
    return (
        <div className="companyfinancials-container">
            <h3>Financials</h3>
            <div>Operating Cash Flow: Query Here</div>
        </div>
    );
}

export default CompanyFinancials;