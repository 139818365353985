import React, { useState, useEffect } from "react";
import "./botparameters.css";

const hourOptions = Array.from({ length: 7 }, (_, i) => {
    return String(i + 6).padStart(2, "0");
});
const minuteOptions = Array.from({ length: 60 }, (_, i) => {
    return String(i).padStart(2, "0");
});
const halfMinuteOptions = Array.from({ length: 30 }, (_, i) => {
    return String(i + 30).padStart(2, '0');
});

const TimeParameterInput = ({ label, value, onChange }) => {
    const [hour, setHour] = useState("12");
    const [minute, setMinute] = useState("50");
    const [period, setPeriod] = useState("PM");

    useEffect(() => {
        setHour(value.split(' ')[0].split(':')[0]);
        setMinute(value.split(' ')[0].split(':')[1]);
        setPeriod(value.split(' ')[1]);
    }, [value]);

    const handleTimeChange = (type, value) => {
        let updatedHour = hour;
        let updatedMinute = minute;
        let updatedPeriod = period;

        // Update either the hour or the minute, depending on event
        if (type === "hour") {
            updatedHour = value;
            updatedPeriod = updatedHour === "12" ? "PM" : "AM";
            if (updatedHour === "06" && parseInt(updatedMinute, 10) < 30) {
                updatedMinute = "30";
            }
        } else if (type === "minute") {
            updatedMinute = value;
        }

        // Update the states
        setHour(updatedHour);
        setMinute(updatedMinute);
        setPeriod(updatedPeriod);

        // Inform the parent component of the new time
        onChange(`${updatedHour}:${updatedMinute} ${updatedPeriod}`);
    };

    return (
        <div>
            <label>{label}</label>
            <select className="time-parameter-hour" value={hour} onChange={(e) => handleTimeChange("hour", e.target.value)}>
                {hourOptions.map((hr) => (
                    <option key={hr} value={hr}>{hr}</option>
                ))}
            </select>
            {hour == "06"
                ? <select className="time-parameter-minute" value={minute} onChange={(e) => handleTimeChange("minute", e.target.value)}>
                    {halfMinuteOptions.map((mn) => (
                        <option key={mn} value={mn}>{mn}</option>
                    ))}
                </select>
                : <select className="time-parameter-minute" value={minute} onChange={(e) => handleTimeChange("minute", e.target.value)}>
                    {minuteOptions.map((mn) => (
                        <option key={mn} value={mn}>{mn}</option>
                    ))}
                </select>
            }

            <span className="time-parameter-period">{period}</span>
        </div>
    );
};

export default TimeParameterInput;

