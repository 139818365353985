import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { QUERY_CURRENT_PRICE, GET_STOCK_INFO } from '../../../utils/queries';
import LoadingComponent from "../LoadingComponent";
import "./stocktitle.css";


const StockTitle = ({ symbol }) => {
    const currentPriceQuery = useQuery(QUERY_CURRENT_PRICE, { variables: { symbol: symbol } });
    const stockInfoQuery = useQuery(GET_STOCK_INFO, { variables: { symbol: symbol }});

    useEffect(() => {
        const interval = setInterval(() => {
            currentPriceQuery.refetch();
        }, 30 * 1000);

        return () => clearInterval(interval);
    }, [currentPriceQuery.refetch]);

    if (currentPriceQuery.loading || stockInfoQuery.loading) {
        return (<>
            <LoadingComponent symbol={symbol} />
        </>);
    }
    const currentPrice = currentPriceQuery.data?.getCurrentPrice?.currentPrice ?? 0; 
    const cik = stockInfoQuery.data?.getStockInfo?.cik ?? "0000000000";
    const secUrl = `https://www.sec.gov/edgar/browse/?CIK=${cik}&owner=exclude`;

    return (
        <div className="company-title">
            <h2>{symbol}</h2>
            <a href={secUrl} target="_blank" rel="noreferrer" style={{ userSelect: 'text' }}><h2 style={{ margin: 0, userSelect: 'text' }}>CIK - <span>{cik}</span></h2></a>
            <h2>{currentPrice}</h2>
        </div>
    );
}

export default StockTitle;