

const Prediction = () => {
    return (
        <div className="prediction-container">
            Prediction
        </div>
    );
}

export default Prediction;