import React from "react";
import { gql, useMutation } from '@apollo/client';
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51KjnrWEiOe39o9udgds3VjC5BYgHGQMC3rpHMyI13s1fh14f0zHQN9KHHxNGurKuqBaoWVawUTB05GIOBgUZh05Q00fGILYhtF");

const CREATE_STRIPE_CHECKOUT_SESSION = gql`
    mutation CreateStripeCheckoutSession($email: String!) {
        createStripeCheckoutSession(email: $email) {
            sessionId
        }
    }
`;


const CreateBot = ({ email, isSubscribed }) => {
    const [createSession, { loading, error }] = useMutation(CREATE_STRIPE_CHECKOUT_SESSION);

    const handleSubscribe = async () => {
        try {
            const { data } = await createSession({ variables: { email } });
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId: data.createStripeCheckoutSession.sessionId });
        } catch (err) {
            console.error("Subscription error:", err);
        }
    };

    return (<>
        <div className="createbot-container">
            <h2>Create your bot!</h2>
            <p>{email}</p>
            <p>Subscription Status: {isSubscribed.toString()}</p>
            <div>
                <ol>
                    <li>Subscribe using Stripe for $10</li>
                    <li>Link Alpaca account</li>
                </ol>
            </div>

            {!isSubscribed && (
                <button onClick={handleSubscribe} disabled={loading}>
                    {loading ? "Processing..." : "Subscribe for $10/month"}
                </button>
            )}
            {isSubscribed && <p>You are already subscribed!</p>}
            {error && <p>Error creating subscription: {error.message}</p>}


        </div>
    </>);
}

export default CreateBot;