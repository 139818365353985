import { useState, useEffect } from "react";

const SubTitle = () => {
    const phrases = [
        "Stay biased!",
        "News is noise.",
        "Buy the news, sell the rumor!",
        "Profit... is inevitable.",
        "Buy the dip!",
        "Make the casino your bank!",
        "That's a nice box of crayons.",
        "Flip the chart upside-down.",
        "Negative operating cash flow? lol no thx",
        "Watching the green paint dry."
    ];
    const [phraseIndex, setPhraseIndex] = useState(Math.floor(Math.random() * phrases.length));
    useEffect(() => {
        const interval = setInterval(() => {
            setPhraseIndex(prevIndex => (prevIndex + 1) % phrases.length);
        }, 30 * 1000);
        return () => clearInterval(interval); // Cleanup on component unmount
    }, [phrases.length]);
    return (<p>{phrases[phraseIndex]}</p>);
}

export default SubTitle;