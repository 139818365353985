

const ParameterError = ({ errors }) => {
    return (
        <div className="botparameters-errors-container">
            {errors.length > 0 && (
                <div>
                    {errors.map((err, index) => (
                        <div key={index}>{err}</div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ParameterError;