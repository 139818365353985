

const ParameterSuccess = ({ errors, successMessage }) => {
    if (!errors.length && successMessage) {
        return (
            <div className="botparameters-success-container">
                <div>
                    {successMessage}
                </div>
            </div>

        );
    }
    return (<></>);

}

export default ParameterSuccess;