import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import "./botparameters.css";
import ParameterInput from "./ParameterInput";
import TimeParameterInput from "./TimeParameterInput";
import ParameterError from "./ParameterError";
import ParameterSuccess from "./ParameterSuccess";

// GraphQL Queries and Mutations
const GET_BOT_PARAMETERS = gql`
    query GetBotParameters($email: String!) {
        getBotParameters(email: $email) {
            sellTarget
            buyTarget
            buyNumber
            tradeTime
            halfDayTradeTime
            maxBuyPercentage
            cashReserve
        }
    }
`;

const UPDATE_BOT_PARAMETERS = gql`
    mutation UpdateBotParameters($email: String!, $parameters: BotParametersInput!) {
        updateBotParameters(email: $email, parameters: $parameters) {
            success
            message
        }
    }
`;

const BotParameters = ({ email }) => {
    const [formData, setFormData] = useState({
        sellTarget: '',
        buyTarget: '',
        buyNumber: '',
        tradeTime: '',
        maxBuyPercentage: '',
        cashReserve: ''
    });
    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const timerRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const botParametersQuery = useQuery(GET_BOT_PARAMETERS, { variables: { email } });
    const botParametersMutation = useMutation(UPDATE_BOT_PARAMETERS, {
        onCompleted: () => {},
        onError: () => {}
    });

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (botParametersQuery.data?.getBotParameters) {
            setFormData({
                sellTarget: botParametersQuery.data.getBotParameters.sellTarget * 100,
                buyTarget: botParametersQuery.data.getBotParameters.buyTarget * 100,
                buyNumber: botParametersQuery.data.getBotParameters.buyNumber,
                tradeTime: botParametersQuery.data.getBotParameters.tradeTime,
                maxBuyPercentage: botParametersQuery.data.getBotParameters.maxBuyPercentage * 100,
                cashReserve: botParametersQuery.data.getBotParameters.cashReserve
            });
        }
    }, [botParametersQuery.data]);

    const handleChange = (name) => (valueOrEvent) => {
        const newValue = valueOrEvent?.target
            ? valueOrEvent.target.value
            : valueOrEvent;

        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const newErrors = [];
        setSuccessMessage("");
        setIsSubmitting(false);

        // Validate Sell Target
        if (!formData.sellTarget) {
            newErrors.push("Sell Target is required.");
        } else if (isNaN(Number(formData.sellTarget))) {
            newErrors.push("Sell Target must be a number.");
        } else if (Number(formData.sellTarget) <= 0) {
            newErrors.push("Sell Target must be a positive number.");
        }

        // Validate Buy Target
        if (!formData.buyTarget) {
            newErrors.push("Buy Target is required.");
        } else if (isNaN(Number(formData.buyTarget))) {
            newErrors.push("Buy Target must be a number.");
        } else if (Number(formData.buyTarget) <= 0) {
            newErrors.push("Buy Target must be a positive number.");
        }

        // Validate Buy Number
        if (!formData.buyNumber) {
            newErrors.push("Buy Number is required.");
        } else if (isNaN(Number(formData.buyNumber))) {
            newErrors.push("Buy Number must be a number.");
        } else if (Number(formData.buyNumber) <= 0) {
            newErrors.push("Buy Number must be a positive number.");
        }

        // Validate Max Buy Percent
        if (!formData.maxBuyPercentage) {
            newErrors.push("Max Buy Percent is required.");
        } else if (isNaN(Number(formData.maxBuyPercentage))) {
            newErrors.push("Max Buy Percent must be a number.");
        } else if (Number(formData.maxBuyPercentage) <= 0) {
            newErrors.push("Max Buy Percent must be a positive number");
        }

        // Validate Cash Reserve
        if (!formData.cashReserve && Number(formData.cashReserve) != 0) {
            newErrors.push("Cash Reserve is required.");
        } else if (isNaN(Number(formData.cashReserve))) {
            newErrors.push("Cash Reserve must be a number.");
        } else if (Number(formData.cashReserve) < 0) {
            newErrors.push("Cash Reserve cannot be a negative number.");
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        } else {
            setErrors([]);
            setSuccessMessage("Form submitted successfully!");
            setIsSubmitting(true);
            
            botParametersMutation[0]({
                variables: {
                    email: email,
                    parameters: {
                        sellTarget: parseFloat(formData.sellTarget) / 100,
                        buyTarget: parseFloat(formData.buyTarget) / 100,
                        buyNumber: parseFloat(formData.buyNumber),
                        tradeTime: formData.tradeTime,
                        maxBuyPercentage: parseFloat(formData.maxBuyPercentage) / 100,
                        cashReserve: parseFloat(formData.cashReserve)
                    }
                }
            });
            
            timerRef.current = setTimeout(() => {
                setSuccessMessage("");
                setIsSubmitting(false);
            }, 5000);
        }
    };

    if (botParametersQuery.loading) return <></>;
    if (botParametersQuery.error) return <></>;

    return (
        <div className="botparameters-container">
            <h2>Bot Parameters</h2>
            <form onSubmit={handleSubmit}>
                <ParameterInput
                    label="Sell Target (%)"
                    value={formData.sellTarget}
                    onChange={handleChange("sellTarget")}
                />
                <ParameterInput
                    label="Buy Target (%)"
                    value={formData.buyTarget}
                    onChange={handleChange("buyTarget")}
                />
                <ParameterInput
                    label="Buy Number"
                    value={formData.buyNumber}
                    onChange={handleChange("buyNumber")}
                />
                <TimeParameterInput
                    label="Trade Time (PST)"
                    value={formData.tradeTime}
                    onChange={handleChange("tradeTime")}
                />
                <ParameterInput
                    label="Max Buy Percent (%)"
                    value={formData.maxBuyPercentage}
                    onChange={handleChange("maxBuyPercentage")}
                />
                <ParameterInput
                    label="Cash Reserve"
                    value={formData.cashReserve}
                    onChange={handleChange("cashReserve")}
                />
                <button className="botparameters-submit-button" disabled={isSubmitting} type="submit">Save</button>
                
                <ParameterError errors={errors} />
                <ParameterSuccess errors={errors} successMessage={successMessage} />
            </form>
        </div>
    );
};

export default BotParameters;
