import React from "react";
import { Route } from "react-router-dom";
import Stock from "../pages/Stock";

function CreateStockRoutes(symbols, loggedin, isSubscribed) {
    return symbols.map(symbol => (
        <Route key={symbol[0]} path={`/${symbol[0]}`} element={<Stock symbol={symbol[0]} loggedin={loggedin} isSubscribed={isSubscribed} />} />
    ));
}

export default CreateStockRoutes;