import React, { useState, useEffect } from 'react';

// Components
import Footer from "../../components/Footer";
import VolatilityContainer from "./VolatilityContainer/VolatilityContainer";
import TotalEquity from "./TotalEquity/TotalEquity";
import PositionsContainer from "./Positions/PositionsContainer";
import BotStatus from "./BotStatus/BotStatus";
import WatchList from './WatchList/WatchList';
import BotParameters from './BotParameters/BotParameters';
import CreateBot from './CreateBot/CreateBot';
import SearchStock from './SearchStock';
import AlpacaLink from './AlpacaLink/AlpacaLink';

const HomeLoggedIn = ({ email, isSubscribed }) => {
    const [cash, setCash] = useState(0); // State to hold the cash value
    const [totalEquity, setTotalEquity] = useState(0);

    // Callback to update the cash value
    const handleCashUpdate = (newCash) => {
        setCash(newCash);
    };
    // Callback to update total equity
    const handleTotalEquityUpdate = (total) => {
        setTotalEquity(total);
    };

    useEffect(() => {
        if (isSubscribed) {
            console.log("User is subscribed.");
        } else {
            console.log("User is not subscribed.");
        }
    }, [isSubscribed]);

    if (isSubscribed) {
        return (<>
            <div className="home-container">
                <div className="component">
                    <AlpacaLink />
                </div>
                
                <div className="joint-component">
                    <VolatilityContainer />
                    <SearchStock />
                </div>
                
                <div className="joint-component">
                    <TotalEquity total={totalEquity} cash={cash} />
                    <BotStatus email={email} updateCash={handleCashUpdate} />
                </div>

                <PositionsContainer email={email} updateTotalEquity={handleTotalEquityUpdate} />
                <div className="joint-component">
                    <WatchList email={email} />
                    <BotParameters email={email} />
                </div>

                <Footer />
            </div>
        </>);
    } else {
        return (<>
            <div className="home-container">
                <CreateBot email={email} isSubscribed={isSubscribed} />
                <Footer />
            </div>
        </>);
    }

    
}

export default HomeLoggedIn;