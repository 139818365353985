import "./timeframes.css";

const TimeFrames = ({ chartMode, setChartMode }) => {
    return (
    <div className="timeframes-container">
        <button onClick={() => setChartMode("5Y")} className={chartMode === "5Y" ? "active" : ""}>5Y</button>
        <button onClick={() => setChartMode("1Y")} className={chartMode === "1Y" ? "active" : ""}>1Y</button>
        <button onClick={() => setChartMode("1M")} className={chartMode === "1M" ? "active" : ""}>1M</button>
        <button onClick={() => setChartMode("1W")} className={chartMode === "1W" ? "active" : ""}>1W</button>
    </div>
    );
}

export default TimeFrames;