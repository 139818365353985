import "./alpacalink.css";

const AlpacaLink = () => {

    return (
        <div className="alpacalink-container">
            <div>You have not linked your Alpaca account! Currently,
                BiasCoin only works if you have an active Alpaca Trade account
                that you link to your BiasCoin subscription.
            </div>
            <button>Link your Alpaca Account</button>
        </div>
    );
}

export default AlpacaLink;