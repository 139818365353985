import { useEffect } from "react";
import { useQuery } from '@apollo/client';
import { GET_VOLATILITY } from '../../../utils/queries';
import "./volatilitycontainer.css";

const VolatilityContainer = () => {
    const volatilityQuery = useQuery(GET_VOLATILITY, {});
    
    useEffect(() => {
        const interval = setInterval(() => {
            volatilityQuery.refetch();
        }, 30 * 1000);

        return () => clearInterval(interval);
    }, [volatilityQuery.refetch]);

    if (volatilityQuery.loading) {
        return (<></>);
    }
    
    const vix = volatilityQuery.data.getVolatility[0].vix;
    const realized = volatilityQuery.data.getVolatility[0].realized;
    const skew = volatilityQuery.data.getVolatility[0].skew;
    const fear = volatilityQuery.data.getVolatility[0].fear;
    const caution = volatilityQuery.data.getVolatility[0].caution;

    return (<div className="volatility-container">
        <div>VIX: {vix}</div>
        <div>Realized: {realized}</div>
        <div>SKEW: {skew}</div>
        <div>Fear: {fear}%</div>
        <div>Caution: {caution}%</div>
    </div>);
}

export default VolatilityContainer;