import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { GET_WATCHLIST } from "../../../utils/queries";
import "./watchlist.css";  // Import the CSS styles
import WatchListHint from "./WatchListHint";

const UPDATE_WATCHLIST = gql`
    mutation UpdateWatchList($email: String!, $symbols: [String]!) {
        updateWatchList(email: $email, symbols: $symbols) {
            success
            message
            symbols
        }
    }
`;

const WatchList = ({ email }) => {
    const { loading, data, refetch } = useQuery(GET_WATCHLIST, { variables: { email } });
    const [symbols, setSymbols] = useState([]);
    const [newSymbol, setNewSymbol] = useState('');
    const [updateWatchList] = useMutation(UPDATE_WATCHLIST);
    const navigate = useNavigate();

    useEffect(() => {
        if (data?.getWatchList?.symbols) {
            setSymbols(data.getWatchList.symbols);
        }
    }, [data]);

    const handleAddSymbol = async () => {
        if (newSymbol.trim() !== '') {
            const formattedSymbol = newSymbol.trim().toUpperCase();
            if (!symbols.includes(formattedSymbol)) {
                const newSymbols = [...symbols, formattedSymbol];
                await updateWatchlist(newSymbols);
                setNewSymbol('');
            }
        }
    };

    const handleDeleteSymbol = async (symbolToDelete) => {
        const filteredSymbols = symbols.filter(symbol => symbol !== symbolToDelete);
        await updateWatchlist(filteredSymbols);
    };

    const updateWatchlist = async (newSymbols) => {
        try {
            await updateWatchList({
                variables: {
                    email,
                    symbols: newSymbols
                }
            });
            refetch();
        } catch (error) {
            console.error('Error updating watchlist', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="watchlist-container">
            <h2>Stock Watchlist</h2>
            <div className="add-watchlist-item-container">
                <input
                    value={newSymbol}
                    onChange={(e) => setNewSymbol(e.target.value)}
                    placeholder="Symbol"
                />
                <button onClick={handleAddSymbol}>+</button>
            </div>
            <div className="watchlist-items">
                {symbols.map((symbol, index) => (
                    <div key={index}>
                        <button onClick={() => handleDeleteSymbol(symbol)}>X</button>
                        <input
                            value={symbol}
                            readOnly
                            onClick={() => navigate(`/${symbol}`)}
                        />
                        <WatchListHint symbol={symbol} />
                    </div>
                ))}

            </div>
            
        </div>
    );
};

export default WatchList;
