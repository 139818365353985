import React, { useRef, useEffect, useState } from 'react';

const StockChart = ({ data, labels = [], mode }) => {
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 600, height: 400 });
    const padding = 60;

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const { offsetWidth } = containerRef.current;
                const calculatedHeight = offsetWidth * 0.66;
                setDimensions({ width: offsetWidth, height: calculatedHeight });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [mode]);

    // Helper to calculate linear regression (drift)
    const calculateDrift = (data) => {
        const n = data.length;
        const sumX = data.reduce((sum, _, i) => sum + i, 0);
        const sumY = data.reduce((sum, y) => sum + y, 0);
        const sumXY = data.reduce((sum, y, i) => sum + i * y, 0);
        const sumXX = data.reduce((sum, _, i) => sum + i * i, 0);

        const slope = ((n * sumXY) - (sumX * sumY)) / ((n * sumXX) - (sumX * sumX));
        const intercept = (sumY - slope * sumX) / n;

        return { slope, intercept };
    };

    // Helper to format timestamps for the X-axis
    const formatLabel = (label) => {
        if (!label) return '';
        const [year, month, day] = label.split('T')[0].split('-');
        return `${month}/${day}/${year}`;
    };
    
    // Draw the chart
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const { width, height } = dimensions;

        // Clear the canvas
        ctx.clearRect(0, 0, width, height);

        // Background
        ctx.fillStyle = '#f8f8f8';
        ctx.fillRect(0, 0, width, height);

        if (data.length === 0) return;

        // Dynamic Y-axis scaling with padding
        const yPaddingRatio = 0.05;  // 5% padding above max and below min

        const maxData = Math.max(...data);
        const minData = Math.min(...data);

        // Add dynamic padding to max and min for tighter scaling
        const paddedMax = maxData + (maxData - minData) * yPaddingRatio;
        const paddedMin = minData - (maxData - minData) * yPaddingRatio;

        // Ensure paddedMin doesn't drop below zero for stock prices
        const adjustedMin = Math.max(0, paddedMin);
        const dataRange = paddedMax - adjustedMin || 1;

        const pointSpacing = (width - padding * 2) / (data.length - 1);

        const getCoordinates = (value, index) => {
            const x = padding + index * pointSpacing;
            const y = height - padding - ((value - adjustedMin) / dataRange) * (height - padding * 2);
            return { x, y };
        };

        // Helper for Y-axis step size
        const calculateStepSize = (range, steps) => {
            const roughStep = range / steps;
            const exponent = Math.floor(Math.log10(roughStep));
            const magnitude = Math.pow(10, exponent);
            return Math.ceil(roughStep / magnitude) * magnitude;
        };

        // Draw dynamic Y-axis labels and gridlines
        const yLabelCount = 6;
        const yStep = calculateStepSize(dataRange, yLabelCount);
        const yAxisStart = Math.floor(adjustedMin / yStep) * yStep;
        const yAxisEnd = Math.ceil(maxData / yStep) * yStep;

        ctx.font = `${Math.max(10, width * 0.02)}px Arial`;
        ctx.fillStyle = '#333';
        ctx.textAlign = 'right';

        for (let value = yAxisStart; value <= yAxisEnd; value += yStep) {
            const y = height - padding - ((value - adjustedMin) / dataRange) * (height - padding * 2);

            // Y-axis labels
            ctx.fillText(value.toFixed(2), padding - 10, y + 3);

            // Y-axis gridlines
            ctx.beginPath();
            ctx.moveTo(padding, y);
            ctx.lineTo(width - padding, y);
            ctx.strokeStyle = '#e0e0e0';
            ctx.stroke();
        }

        // Draw the data line
        ctx.beginPath();
        data.forEach((value, index) => {
            const { x, y } = getCoordinates(value, index);
            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.strokeStyle = '#007bff';
        ctx.lineWidth = 2;
        ctx.stroke();

         // Draw dashed line for the maximum value
         const maxY = height - padding - ((maxData - adjustedMin) / dataRange) * (height - padding * 2);
         ctx.beginPath();
         ctx.setLineDash([5, 5]);
         ctx.moveTo(padding, maxY);
         ctx.lineTo(width - padding, maxY);
         ctx.strokeStyle = 'green';
         ctx.stroke();
         ctx.setLineDash([]);
         ctx.fillStyle = 'black';
         ctx.fillText(`Max: ${maxData.toFixed(2)}`, width - padding + 10, maxY - 16);
 
         // Draw dashed line for the minimum value
         const minY = height - padding - ((minData - adjustedMin) / dataRange) * (height - padding * 2);
         ctx.beginPath();
         ctx.setLineDash([5, 5]);
         ctx.moveTo(padding, minY);
         ctx.lineTo(width - padding, minY);
         ctx.strokeStyle = 'red';
         ctx.stroke();
         ctx.setLineDash([]);
         ctx.fillStyle = 'black';
         ctx.fillText(`Min: ${minData.toFixed(2)}`, width - padding + 10, minY - 20);
         
        // Draw the Drift (Trend) Line
        const { slope, intercept } = calculateDrift(data);

        // Helper function to clamp values within the chart's height
        const clampY = (y) => {
            return Math.max(padding, Math.min(height - padding, y));
        };

        // Calculate the drift line's start and end Y positions
        let startDriftY = slope * 0 + intercept;
        let endDriftY = slope * (data.length - 1) + intercept;

        // Get clamped coordinates to prevent overflow
        let { x: startX, y: startY } = getCoordinates(startDriftY, 0);
        let { x: endX, y: endY } = getCoordinates(endDriftY, data.length - 1);

        // Clamp the Y-coordinates to keep the line within the chart area
        startY = clampY(startY);
        endY = clampY(endY);

        // Draw the clamped drift line
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = 'orange';
        ctx.setLineDash([10, 5]);  // Dashed line for drift
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.setLineDash([]);  // Reset dash
        ctx.fillStyle = 'black';
        ctx.fillText(`Drift (m=${slope.toFixed(2)})`, endX - 70, endY - 10);

        // Draw X-axis labels: first, last, and a few in between
        const totalLabels = 6;  // Total labels to show (including first and last)
        const interval = Math.max(1, Math.floor((data.length - 2) / (totalLabels - 2)));  // Exclude first and last

        ctx.textAlign = 'center';
        ctx.fillStyle = '#000';  // Black font for X-axis labels

        // Draw the first label (leftmost)
        const { x: firstX } = getCoordinates(data[0], 0);
        const firstLabel = labels[0] ? formatLabel(labels[0]) : `P1`;
        ctx.fillText(firstLabel, firstX, height - padding + 20);

        // Draw intermediate labels (excluding the last label)
        // Draw evenly spaced intermediate labels, explicitly avoiding the last label
        for (let i = interval; i < data.length - 1; i += interval) {
            const { x } = getCoordinates(data[i], i);

            // Ensure this label doesn't overlap with the last one
            if (x < width - padding - 10) {
                const label = labels[i] ? formatLabel(labels[i]) : `P${i + 1}`;
                ctx.fillText(label, x, height - padding + 20);
            }
        }

        // Draw the last label (rightmost)
        const { x: lastX } = getCoordinates(data[data.length - 1], data.length - 1);
        const lastLabel = labels[labels.length - 1] ? formatLabel(labels[labels.length - 1]) : `P${data.length}`;
        ctx.fillText(lastLabel, lastX, height - padding + 20);

        // Draw X-axis and Y-axis
        ctx.beginPath();
        ctx.moveTo(padding, padding);
        ctx.lineTo(padding, height - padding);
        ctx.moveTo(padding, height - padding);
        ctx.lineTo(width - padding, height - padding);
        ctx.strokeStyle = '#333';
        ctx.stroke();

    }, [data, labels, dimensions]);

    return (
        <div ref={containerRef} className="stockchart-container" style={{ width: '100%' }}>
            <canvas
                ref={canvasRef}
                width={dimensions.width}
                height={dimensions.height}
                style={{ width: '100%', height: 'auto', border: '1px solid black' }}
            />
        </div>
    );
};

export default StockChart;
