import './stock.css';
import Footer from '../../components/Footer';
import Patron from './Patron/Patron';
import StockTitle from './StockTitle';
import StockChart from "./StockChart/StockChart";
import TimeFrames from "./TimeFrames/TimeFrames";
import { useQuery } from "@apollo/client";
import { GET_STOCK_HISTORY } from '../../utils/queries';
import { useState } from "react";
import CompanyFinancials from './CompanyFinancials/CompanyFinancials';
import Prediction from "./Prediction/Prediction";

const Stock = ({ symbol, loggedin, isSubscribed }) => {
    const [chartMode, setChartMode] = useState("5Y");
    const stockDataQuery = useQuery(GET_STOCK_HISTORY, { 
        variables: { symbol: symbol, timeframe: "Daily" } 
    });

    if (stockDataQuery.loading) return <></>;
    if (stockDataQuery.error) return <></>;

    const closingPrices = stockDataQuery.data.getHistoricalPrices.map(item => item.c);
    const timestamps = stockDataQuery.data.getHistoricalPrices.map(item => item.timestamp);

    // Helper to filter data based on chart mode
    const filterDataByMode = (mode) => {
        switch (mode) {
            case "5Y":
                return { prices: closingPrices, labels: timestamps };  // All data
            case "1Y":
                return { 
                    prices: closingPrices.slice(-252),  // Last 252 trading days (~1 year)
                    labels: timestamps.slice(-252) 
                };
            case "1M":
                return { 
                    prices: closingPrices.slice(-20),  // Last 20 trading days (~1 month)
                    labels: timestamps.slice(-20) 
                };
            case "1W":
                return { 
                    prices: closingPrices.slice(-5),  // Last 5 trading days (~1 week)
                    labels: timestamps.slice(-5) 
                };
            default:
                return { prices: closingPrices, labels: timestamps };
        }
    };

    const { prices, labels } = filterDataByMode(chartMode);

    return (
        <div className="stock-container">
            <StockTitle symbol={symbol} />
            <StockChart data={prices} labels={labels} mode={chartMode} />
            <TimeFrames chartMode={chartMode} setChartMode={setChartMode} />
            <hr />
            <Prediction />
            <hr />
            <CompanyFinancials />
            <Footer />
        </div>
    );
}

export default Stock;