import { gql, useQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Auth from "../../utils/auth";
import { useNavigate } from "react-router-dom";

export const GET_SUBSCRIPTION_STATUS = gql`
    query GetSubscriptionStatus($email: String!) {
        getSubscriptionStatus(email: $email) {
            isSubscribed
        }
    }
`;
export const REFRESH_TOKEN = gql`
    mutation RefreshToken($email: String!) {
        refreshToken(email: $email) {
            token
        }
    }
`;

const Success = () => {
    const [refreshToken] = useMutation(REFRESH_TOKEN);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const email = Auth.loggedIn() ? Auth.getProfile().data.email : "";

    useEffect(() => {
        const refreshUserToken = async () => {
            try {
                const { data } = await refreshToken({
                    variables: { email }
                });

                if (data && data.refreshToken.token) {
                    // Update the cookie with the new token
                    Auth.login(data.refreshToken.token);

                    // Redirect to the dashboard after 3 seconds
                    setTimeout(() => {
                        navigate('/');
                    }, 3000);
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (email) {
            refreshUserToken();
        }
    }, [email, refreshToken, navigate]);

    if (loading) return <p>Updating your subscription status...</p>;
    if (error) return <p>Error updating subscription status.</p>;
    
    return (
        <div className="success-container">
            <p></p>
        </div>
    );
}

export default Success;