import React, { useState } from "react";
import "./botparameters.css";

const ParameterInput = ({ label, value, onChange, validate }) => {
    
    return (
        <div>
            <label>{label}</label>
            <input type="text" value={value} onChange={onChange} />
        </div>
    );
}

export default ParameterInput;